<template lang="pug">
    div
        header-component
        router-view
</template>
<script>
import HeaderComponent from '@/components/TeacherLayout/HeaderComponent'
export default {
    name: 'TeacherLayout', //nombre del componente
    components: {
        HeaderComponent
    },
    beforeMount() {
    },
    destroyed() {
    }
}
</script>